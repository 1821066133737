import moment from 'moment'
import { useConnectionStore } from '@/stores/ConnectionStore'
import { ALL_CONNECTION_CHANNELS, FORM_CONNECTION_OPTIONS_VALUES, HANDLING_TIME_VALUES, EBAY_CATEGORIES } from '@/constants'
import { cleanStatus, camelize } from '@/helpers/text'
import { POLICIES_IDS_NAMES_IN_TEMPLATE, POLICIES_IDS_NAMES_IN_POLICY_LIST } from '@/constants'

export const getDefaultTemplateForStore = (listingTemplates, selectedTemplate) => {
  return selectedTemplate?.id ? selectedTemplate : listingTemplates.find(item => item?.default) || listingTemplates[0]
}

export const getConnections = (user = {}) => {
  return ALL_CONNECTION_CHANNELS.reduce((acc, item) => {
    let connection = { ...item, active: false }

    if (item.key === 'collx' && user.collx_enabled && user.collx_user_id) {
      connection = { ...connection, active: true }
    }

    if (item.key === 'ebay' && user.ebay_enabled && user.ebay_profile) {
      connection = { ...connection, active: true }
    }

    if (item.key === 'whatnot' && user.whatnot_enabled && user.whatnot_profile) {
      connection = { ...connection, active: true }
    }

    acc.push(connection)

    return acc
  }, [])
}

export const preparedFieldsForEbayDirectType = (selectedTemplate = {}) => {
  const {
    ebay_category,
    name,
    store_category,
    listing_type,
    listing_price,
    price_adjustment_type,
    calculated_method,
    fixed_amount_to_price,
    percent_to_price,
    allow_best_offer,
    auto_accept_offer,
    auto_decline_offer,
    schedule_time,
    schedule_delay,
    start_price,
    enable_buy_it_now_price,
    auto_accept_offer_threshold,
    auto_decline_offer_threshold,
    immediate_payment,
    buy_it_now_price,
    duration,
    make_live_immediately,
    type,
    default: defaultTemplate,
    schedule_date,
    set_for_all,
    schedule_interval,
    use_cert_as_sku,
    ebay_fulfillment_policy_id,
    ebay_location_id,
    ebay_payment_policy_id,
    ebay_return_policy_id
  } = selectedTemplate

  return {
    ebay_category,
    name,
    store_category,
    listing_type,
    listing_price,
    price_adjustment_type,
    calculated_method,
    fixed_amount_to_price,
    percent_to_price,
    allow_best_offer,
    auto_accept_offer,
    auto_decline_offer,
    schedule_time,
    schedule_delay,
    calculated_method,
    start_price,
    buy_it_now_price,
    enable_buy_it_now_price,
    auto_accept_offer_threshold,
    auto_decline_offer_threshold,
    immediate_payment,
    buy_it_now_price,
    duration,
    make_live_immediately,
    type,
    default: defaultTemplate,
    schedule_date,
    set_for_all,
    schedule_interval,
    use_cert_as_sku,
    ebay_fulfillment_policy_id,
    ebay_location_id,
    ebay_payment_policy_id,
    ebay_return_policy_id
  }
}

export const findCategoryById = (categories, id) => {
  // Iterate over each category
  for (const category of categories) {
      // Check if the current category's ID matches the search ID
      if (category.categoryId === id) {
          return category
      }
      // If the current category has children, recurse into them
      if (category.childrenCategories.length > 0) {
          const found = findCategoryById(category.childrenCategories, id)
          if (found) {
              return found
          }
      }
  }
  // Return null if no matching category is found in this branch
  return null
}

export const getChannelTypes = (fullPath) => {
  let type = 'listingTemplates'
  let listingType = 'ebay'

  if (fullPath.includes('collx')) {
    type = 'collxListingTemplates'
    listingType = 'collx'
  } else if (fullPath.includes('whatnot')) {
    type = 'whatnotListingTemplates'
    listingType = 'whatnot'
  }

  return {
    type,
    listingType
  }
}

export const getItemLocation = (info) => {
  if (!info || (!info?.city && !info?.state && !info?.zip_code)) return false

  return `${ info.city || '' }, ${ info.state || '' } ${ info.zip_code || ''}`
}

export const getConnectionType = (path) => {
  if (path.includes('ebay')) {
    return 'ebay'
  } else if (path.includes('whatnot')) {
    return 'whatnot'
  } else if (path.includes('collx')) {
    return 'collx'
  } else if (path.includes('shopify')) {
    return 'shopify'
  }
}

export const getCollxExportFields = () => {
  const connectionStore = useConnectionStore()

  return [
    {
      isDisplay: true,
      label: 'Price Format',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export?.data.listing_type === 'fixed'
        ? (connectionStore.export?.data.price_override 
          ? 'Price Override' 
          : !connectionStore.export?.data.listing_price
            ? 'Calculated Price'
            : 'Standard Price')
        : connectionStore.export?.data.start_price
          ? `${connectionStore.export?.data.start_price} $`
          : '-',
    },
    {
      isDisplay: connectionStore.export?.data.listing_type === 'fixed' && !connectionStore.selectedTemplate.listing_price,
      label: `${connectionStore.export?.data.price_adjustment_type === 'increase' ? 'Increase': 'Decrease'} Sale Price By`,
      isTruncated: true,
      isTextGray400: false,
      value: !connectionStore.export.data?.calculated_method ? 'Percentage' : 'Fixed Amount',
    },
    {
      isDisplay: connectionStore.export?.data.listing_type === 'fixed' && !connectionStore.selectedTemplate.listing_price && connectionStore.selectedTemplate.calculated_method,
      label: 'Add Fixed Amount to Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.data?.fixed_amount_to_price || '-',
    },
    {
      isDisplay: connectionStore.export?.data.listing_type === 'fixed' && !connectionStore.selectedTemplate.listing_price && !connectionStore.selectedTemplate.calculated_method,
      label: 'Add Percentage to Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.data?.percent_to_price || '-',
    },
    {
      isDisplay: true,
      label: 'CollX Sale Price',
      isTruncated: true,
      isTextGray400: false,
      value: 'Sale Price' || '-',
    },
  ]
}

export const getEbayExportFields = () => {
  const connectionStore = useConnectionStore()

  return [
    {
      isDisplay: true,
      label: 'Category Number',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile.ebay_category,
      value: EBAY_CATEGORIES[connectionStore.selectedTemplate.ebay_category] || `Other (#${connectionStore.selectedTemplate.ebay_category})`,
    },
    {
      isDisplay: connectionStore.export.profile.store_category,
      label: 'Store Category Number',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile.store_category,
      value: connectionStore.export.profile.store_category || '-',
    },
    {
      isDisplay: true,
      label: 'Sales Format',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.listing_type,
      value: connectionStore.export.profile?.listing_type && connectionStore.export.profile.listing_type === 'fixed' ? 'Fixed Price' : 'Auction' || '-',
    },
    {
      isDisplay: true,
      label: 'Best offer',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.allow_best_offer ? 'On' : 'Off',
    },
    {
      isDisplay: connectionStore.export.profile.allow_best_offer,
      label: 'Automatically accept offers of at least',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.auto_accept_offer,
      value: connectionStore.export.profile.auto_accept_offer ? `${connectionStore.export.profile.auto_accept_offer_threshold} %` : '-',
    },
    {
      isDisplay: connectionStore.export.profile.allow_best_offer,
      label: 'Automatically decline offers lower than',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.auto_decline_offer,
      value: connectionStore.export.profile.auto_decline_offer ? `${connectionStore.export.profile.auto_decline_offer_threshold} %` : '-',
    },
    {
      isDisplay: true,
      label: 'Listing Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.listing_type === 'fixed' ? !connectionStore.export.profile.listing_price ? 'Calculated Price' : 'Standard Price' : `${connectionStore.export.profile.start_price} $`  || '-',
    },
    {
      isDisplay: connectionStore.export.profile.listing_type === 'fixed' && !connectionStore.export.profile?.listing_price,
      label: `${connectionStore.export.profile.price_adjustment_type === 'increase' ? 'Increase': 'Decrease'} Sale Price By`,
      isTruncated: true,
      isTextGray400: false,
      value: !connectionStore.export.profile.calculated_method ? 'Percentage' : 'Fixed Amount',
    },
    {
      isDisplay: connectionStore.export.profile.listing_type === 'fixed' && !connectionStore.export.profile?.listing_price && connectionStore.export.profile.calculated_method,
      label: 'Add Fixed Amount to Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.fixed_amount_to_price || '-',
    },
    {
      isDisplay: connectionStore.export.profile.listing_type === 'fixed' && !connectionStore.export.profile?.listing_price && !connectionStore.export.profile.calculated_method,
      label: 'Add Percentage to Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.percent_to_price || '-',
    },
    {
      isDisplay: connectionStore.export.profile.listing_type !== 'fixed' && connectionStore.export.profile.enable_buy_it_now_priceue,
      label: 'Buy It Now Price',
      isTruncated: true,
      isTextGray400: false,
      value: '$' + connectionStore.export.profile.buy_it_now_price,
    },
    {
      isDisplay: true,
      label: 'Duration',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile.duration,
      value: connectionStore.export.profile.duration || '-',
    },
    {
      isDisplay: connectionStore.export.profile.make_live_immediately,
      label: 'Make live immediately',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile.make_live_immediately,
      label: 'Schedule start time',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile.make_live_immediately,
      label: 'Schedule date',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_date ? moment(connectionStore.export.profile.schedule_date).format('MMMM DD YYYY') : '-',
    },
    {
      isDisplay: !connectionStore.export.profile.make_live_immediately,
      label: 'Schedule time',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_time || '-',
    },
    {
      isDisplay: connectionStore.export.profile.set_for_all,
      label: 'Set for all',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile.set_for_all,
      label: 'Staggered',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile.set_for_all,
      label: 'Schedule interval',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_interval || '-',
    },
    {
      isDisplay: !connectionStore.export.profile.set_for_all,
      label: 'Schedule delay',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_delay || '-',
    },
    {
      isDisplay: !connectionStore.export.profile.set_for_all,
      label: 'Schedule delay',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_delay || '-',
    },
    {
      isDisplay: true,
      label: 'SKU Preferences',
      isTruncated: true,
      isTextGray400: false,
      value: 'Set Cert No. for SKU: ' + (connectionStore.export.profile.use_cert_as_sku ? 'On' : 'Off'),
    },
    {
      isDisplay: true,
      label: 'Handling Time',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile.handling_time,
      value: HANDLING_TIME_VALUES[connectionStore.export.profile.handling_time] || '-',
    },
    {
      isDisplay: true,
      label: 'Free Shipping',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.free_shipping ? 'On' : 'Off',
    },
    {
      isDisplay: !connectionStore.export.profile.free_shipping,
      label: 'Cost',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.shipping_cost ? '$' + Number(connectionStore.export.profile.shipping_cost).toFixed(2) : '-',
    },
    {
      isDisplay: !connectionStore.export.profile.free_shipping,
      label: 'Each Additional Item',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.additional_items ? '$' + Number(connectionStore.export.profile.additional_items).toFixed(2) : '-',
    },
    {
      isDisplay: connectionStore.export.profile.shipping_profile_name,
      label: 'Shipping Profile',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.shipping_profile_name,
    },
    {
      isDisplay: true,
      label: 'Immediate Payment',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.immediate_payment ? 'On' : 'Off',
    },
    {
      isDisplay: true,
      label: 'Item Location',
      isTruncated: false,
      isTextGray400: !getItemLocation(connectionStore.export.profile),
      value: getItemLocation(connectionStore.export.profile) || '-',
    },
    {
      isDisplay: true,
      label: 'Sales Tax',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.sales_tax_enabled ? 'On' : 'Off',
    },
    {
      isDisplay: connectionStore.export.profile.sales_tax_enabled,
      label: 'State',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.sales_tax_state,
      value: connectionStore.export.profile.sales_tax_state || '-',
    },
    {
      isDisplay: connectionStore.export.profile.sales_tax_enabled,
      label: 'Tax',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.sales_tax_rate,
      value: connectionStore.export.profile.tax ? connectionStore.export.profile.sales_tax_rate + '%' : '-',
    },
    {
      isDisplay: true,
      label: 'Also apply to shipping and handling cost',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.apply_tax_to_shipping ? 'Yes' : 'No',
    },
    {
      isDisplay: connectionStore.export.profile.return_profile_name,
      label: 'Return Profile',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.return_profile_name,
    },
    {
      isDisplay: true,
      label: 'Returns Accepted',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.returns ? 'On' : 'Off',
    },
    {
      isDisplay: true,
      label: 'Within',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.returns_within,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile.returns_within] || '-',
    },
    {
      isDisplay: true,
      label: 'Refund',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.refund_given_as,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile.refund_given_as] || '-',
    },
    {
      isDisplay: true,
      label: 'Shipping Cost Paid By',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.return_shipping_paid_by,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile.return_shipping_paid_by] || '-',
    },
    {
      isDisplay: true,
      label: 'Additional Return Info',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.additional_return_info,
      value: connectionStore.export.profile.additional_return_info || '-',
    }
  ]
}

export const getWhatnotExportFields = () => {
  const connectionStore = useConnectionStore()

  return [
    {
      isDisplay: true,
      label: 'Category Number',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile?.ebay_category,
      value: connectionStore.export.profile?.ebay_category || '-',
      key: 'ebay_category'
    },
    {
      isDisplay: connectionStore.export.profile.store_category,
      label: 'Store Category Number',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile.store_category,
      value: connectionStore.export.profile.store_category || '-',
    },
    {
      isDisplay: true,
      label: 'Sales Format',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.listing_type,
      value: connectionStore.export.profile?.listing_type && connectionStore.export.profile.listing_type === 'fixed' ? 'Fixed Price' : 'Auction' || '-',
    },
    {
      isDisplay: true,
      label: 'Best offer',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.allow_best_offer ? 'On' : 'Off',
    },
    {
      isDisplay: connectionStore.export.profile.allow_best_offer,
      label: 'Automatically accept offers of at least',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.auto_accept_offer,
      value: connectionStore.export.profile.auto_accept_offer ? `${connectionStore.export.profile.auto_accept_offer_threshold} %` : '-',
    },
    {
      isDisplay: connectionStore.export.profile.allow_best_offer,
      label: 'Automatically decline offers lower than',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.auto_decline_offer,
      value: connectionStore.export.profile.auto_decline_offer ? `${connectionStore.export.profile.auto_decline_offer_threshold} %` : '-',
    },
    {
      isDisplay: true,
      label: 'Listing Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.listing_type === 'fixed' ? !connectionStore.export.profile.listing_price ? 'Calculated Price' : 'Standard Price' : `${connectionStore.export.profile.start_price} $`  || '-',
    },
    {
      isDisplay: connectionStore.export.profile.listing_type === 'fixed' && !connectionStore.export.profile?.listing_price,
      label: `${connectionStore.export.profile.price_adjustment_type === 'increase' ? 'Increase': 'Decrease'} Sale Price By`,
      isTruncated: true,
      isTextGray400: false,
      value: !connectionStore.export.profile.calculated_method ? 'Percentage' : 'Fixed Amount',
    },
    {
      isDisplay: connectionStore.export.profile.listing_type === 'fixed' && !connectionStore.export.profile?.listing_price && connectionStore.export.profile.calculated_method,
      label: 'Add Fixed Amount to Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.fixed_amount_to_price || '-',
    },
    {
      isDisplay: connectionStore.export.profile.listing_type === 'fixed' && !connectionStore.export.profile?.listing_price && !connectionStore.export.profile.calculated_method,
      label: 'Add Percentage to Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.percent_to_price || '-',
    },
    {
      isDisplay: connectionStore.export.profile.listing_type !== 'fixed' && connectionStore.export.profile.enable_buy_it_now_priceue,
      label: 'Buy It Now Price',
      isTruncated: true,
      isTextGray400: false,
      value: '$' + connectionStore.export.profile.buy_it_now_price,
    },
    {
      isDisplay: true,
      label: 'Duration',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile.duration,
      value: connectionStore.export.profile.duration || '-',
    },
    {
      isDisplay: connectionStore.export.profile.make_live_immediately,
      label: 'Make live immediately',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile.make_live_immediately,
      label: 'Schedule start time',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile.make_live_immediately,
      label: 'Schedule date',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_date ? moment(connectionStore.export.profile.schedule_date).format('MMMM DD YYYY') : '-',
    },
    {
      isDisplay: !connectionStore.export.profile.make_live_immediately,
      label: 'Schedule time',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_time || '-',
    },
    {
      isDisplay: connectionStore.export.profile.set_for_all,
      label: 'Set for all',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile.set_for_all,
      label: 'Staggered',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile.set_for_all,
      label: 'Schedule interval',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_interval || '-',
    },
    {
      isDisplay: !connectionStore.export.profile.set_for_all,
      label: 'Schedule delay',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_delay || '-',
    },
    {
      isDisplay: !connectionStore.export.profile.set_for_all,
      label: 'Schedule delay',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile.schedule_delay || '-',
    },
    {
      isDisplay: true,
      label: 'SKU Preferences',
      isTruncated: true,
      isTextGray400: false,
      value: 'Set Cert No. for SKU: ' + (connectionStore.export.profile.use_cert_as_sku ? 'On' : 'Off'),
    },
    {
      isDisplay: true,
      label: 'Handling Time',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile.handling_time,
      value: HANDLING_TIME_VALUES[connectionStore.export.profile.handling_time] || '-',
    },
    {
      isDisplay: true,
      label: 'Free Shipping',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.free_shipping ? 'On' : 'Off',
    },
    {
      isDisplay: !connectionStore.export.profile.free_shipping,
      label: 'Cost',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.shipping_cost ? '$' + Number(connectionStore.export.profile.shipping_cost).toFixed(2) : '-',
    },
    {
      isDisplay: !connectionStore.export.profile.free_shipping,
      label: 'Each Additional Item',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.additional_items ? '$' + Number(connectionStore.export.profile.additional_items).toFixed(2) : '-',
    },
    {
      isDisplay: connectionStore.export.profile.shipping_profile_name,
      label: 'Shipping Profile',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.shipping_profile_name,
    },
    {
      isDisplay: true,
      label: 'Immediate Payment',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.immediate_payment ? 'On' : 'Off',
    },
    {
      isDisplay: true,
      label: 'Item Location',
      isTruncated: false,
      isTextGray400: !getItemLocation(connectionStore.export.profile),
      value: getItemLocation(connectionStore.export.profile) || '-',
    },
    {
      isDisplay: true,
      label: 'Sales Tax',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.sales_tax_enabled ? 'On' : 'Off',
    },
    {
      isDisplay: connectionStore.export.profile.sales_tax_enabled,
      label: 'State',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.sales_tax_state,
      value: connectionStore.export.profile.sales_tax_state || '-',
    },
    {
      isDisplay: connectionStore.export.profile.sales_tax_enabled,
      label: 'Tax',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.sales_tax_rate,
      value: connectionStore.export.profile.tax ? connectionStore.export.profile.sales_tax_rate + '%' : '-',
    },
    {
      isDisplay: true,
      label: 'Also apply to shipping and handling cost',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.apply_tax_to_shipping ? 'Yes' : 'No',
    },
    {
      isDisplay: connectionStore.export.profile.return_profile_name,
      label: 'Return Profile',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.return_profile_name,
    },
    {
      isDisplay: true,
      label: 'Returns Accepted',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile.returns ? 'On' : 'Off',
    },
    {
      isDisplay: true,
      label: 'Within',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.returns_within,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile.returns_within] || '-',
    },
    {
      isDisplay: true,
      label: 'Refund',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.refund_given_as,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile.refund_given_as] || '-',
    },
    {
      isDisplay: true,
      label: 'Shipping Cost Paid By',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.return_shipping_paid_by,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile.return_shipping_paid_by] || '-',
    },
    {
      isDisplay: true,
      label: 'Additional Return Info',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile.additional_return_info,
      value: connectionStore.export.profile.additional_return_info || '-',
    }
  ]
}

export const getShopifyExportFields = () => {
  const connectionStore = useConnectionStore()

  return [
    {
      isDisplay: true,
      label: 'Category Number',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile?.ebay_category,
      value: connectionStore.export.profile?.ebay_category || '-',
    },
    {
      isDisplay: connectionStore.export.profile?.store_category,
      label: 'Store Category Number',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile?.store_category,
      value: connectionStore.export.profile?.store_category || '-',
    },
    {
      isDisplay: true,
      label: 'Sales Format',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.listing_type,
      value: connectionStore.export.profile?.listing_type && connectionStore.export.profile.listing_type === 'fixed' ? 'Fixed Price' : 'Auction' || '-',
    },
    {
      isDisplay: true,
      label: 'Best offer',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.allow_best_offer ? 'On' : 'Off',
    },
    {
      isDisplay: connectionStore.export.profile?.allow_best_offer,
      label: 'Automatically decline offers lower than',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.auto_decline_offer,
      value: connectionStore.export.profile?.auto_decline_offer ? `${connectionStore.export.profile?.auto_decline_offer_threshold} %` : '-',
    },
    {
      isDisplay: true,
      label: 'Listing Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile?.listing_type === 'fixed' ? !connectionStore.export.profile?.listing_price ? 'Calculated Price' : 'Standard Price' : `${connectionStore.export.profile?.start_price} $`  || '-',
    },
    {
      isDisplay: connectionStore.export.profile?.listing_type === 'fixed' && !connectionStore.selectedTemplate?.listing_price,
      label: `${connectionStore.export.profile?.price_adjustment_type === 'increase' ? 'Increase': 'Decrease'} Sale Price By`,
      isTruncated: true,
      isTextGray400: false,
      value: !connectionStore.export.profile?.calculated_method ? 'Percentage' : 'Fixed Amount',
    },
    {
      isDisplay: connectionStore.export.profile?.listing_type === 'fixed' && !connectionStore.selectedTemplate?.listing_price && connectionStore.selectedTemplate.calculated_method,
      label: 'Add Fixed Amount to Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile?.fixed_amount_to_price || '-',
    },
    {
      isDisplay: connectionStore.export.profile?.listing_type === 'fixed' && !connectionStore.selectedTemplate?.listing_price && !connectionStore.selectedTemplate.calculated_method,
      label: 'Add Percentage to Price',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile?.percent_to_price || '-',
    },
    {
      isDisplay: true,
      label: 'Duration',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile?.duration,
      value: connectionStore.export.profile?.duration || '-',
    },
    {
      isDisplay: connectionStore.export.profile?.make_live_immediately,
      label: 'Make live immediately',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile?.make_live_immediately,
      label: 'Schedule start time',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile?.make_live_immediately,
      label: 'Schedule date',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile?.schedule_date ? moment(connectionStore.export.profile?.schedule_date).format('MMMM DD YYYY') : '-',
    },
    {
      isDisplay: !connectionStore.export.profile?.make_live_immediately,
      label: 'Schedule time',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile?.schedule_time || '-',
    },
    {
      isDisplay: connectionStore.export.profile?.set_for_all,
      label: 'Set for all',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile?.set_for_all,
      label: 'Staggered',
      isTruncated: true,
      isTextGray400: false,
      value: 'On',
    },
    {
      isDisplay: !connectionStore.export.profile?.set_for_all,
      label: 'Schedule interval',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile?.schedule_interval || '-',
    },
    {
      isDisplay: !connectionStore.export.profile?.set_for_all,
      label: 'Schedule delay',
      isTruncated: true,
      isTextGray400: false,
      value: connectionStore.export.profile?.schedule_delay || '-',
    },
    {
      isDisplay: true,
      label: 'Shipping Service',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile?.shipping_service,
      value: connectionStore.export.profile?.shipping_service || '-',
    },
    {
      isDisplay: true,
      label: 'Handling Time',
      isTruncated: true,
      isTextGray400: !connectionStore.export.profile?.handling_time,
      value: HANDLING_TIME_VALUES[connectionStore.export.profile?.handling_time] || '-',
    },
    {
      isDisplay: true,
      label: 'Free Shipping',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.free_shipping ? 'On' : 'Off',
    },
    {
      isDisplay: !connectionStore.export.profile?.free_shipping,
      label: 'Cost',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.shipping_cost ? '$' + Number(connectionStore.export.profile?.shipping_cost).toFixed(2) : '-',
    },
    {
      isDisplay: !connectionStore.export.profile?.free_shipping,
      label: 'Each Additional Item',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.additional_items ? '$' + Number(connectionStore.export.profile?.additional_items).toFixed(2) : '-',
    },
    {
      isDisplay: connectionStore.export.profile?.shipping_profile_name,
      label: 'Shipping Profile',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.shipping_profile_name,
    },
    /* {
      isDisplay: true,
      label: 'PayPal Email Address',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.paypal_email,
      value: connectionStore.export.profile?.paypal_email || '-',
    },
    {
      isDisplay: true,
      label: 'Payment Instructions',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.payment_instructions,
      value: connectionStore.export.profile?.payment_instructions || '-',
    }, */
    {
      isDisplay: true,
      label: 'Immediate Payment',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.immediate_payment ? 'On' : 'Off',
    },
    {
      isDisplay: true,
      label: 'Item Location',
      isTruncated: false,
      isTextGray400: !getItemLocation(connectionStore.export.profile),
      value: getItemLocation(connectionStore.export.profile),
    },
    {
      isDisplay: true,
      label: 'Sales Tax',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.sales_tax_enabled ? 'On' : 'Off',
    },
    {
      isDisplay: connectionStore.export.profile?.sales_tax_enabled,
      label: 'State',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.sales_tax_state,
      value: connectionStore.export.profile?.sales_tax_state || '-',
    },
    {
      isDisplay: connectionStore.export.profile?.sales_tax_enabled,
      label: 'Tax',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.sales_tax_rate,
      value: connectionStore.export.profile?.tax ? connectionStore.export.profile?.sales_tax_rate + '%' : '-',
    },
    {
      isDisplay: true,
      label: 'Also apply to shipping and handling cost',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.apply_tax_to_shipping ? 'Yes' : 'No',
    },
    {
      isDisplay: connectionStore.export.profile?.return_profile_name,
      label: 'Return Profile',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.return_profile_name,
    },
    {
      isDisplay: true,
      label: 'Returns Accepted',
      isTruncated: false,
      isTextGray400: false,
      value: connectionStore.export.profile?.returns ? 'On' : 'Off',
    },
    {
      isDisplay: true,
      label: 'Within',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.returns_within,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile?.returns_within] || '-',
    },
    {
      isDisplay: true,
      label: 'Refund',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.refund_given_as,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile?.refund_given_as] || '-',
    },
    {
      isDisplay: true,
      label: 'Shipping Cost Paid By',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.return_shipping_paid_by,
      value: FORM_CONNECTION_OPTIONS_VALUES[connectionStore.export.profile?.return_shipping_paid_by] || '-',
    },
    {
      isDisplay: true,
      label: 'Additional Return Info',
      isTruncated: false,
      isTextGray400: !connectionStore.export.profile?.additional_return_info,
      value: connectionStore.export.profile?.additional_return_info || '-',
    },
  ]
}

export const mappedEbayPolicyPaymentMethods = (paymentMethods = []) => {
  const methods = paymentMethods.reduce((acc, method) => {
    const method_ = cleanStatus(camelize(method.paymentMethodType.toLowerCase()))
    acc.push(method_)

    return acc
  }, [])

  return methods.join(', ')
} 

export const mappedEbayPolicyCategoryTypes = (categoryTypes = []) => {
  const types = categoryTypes.reduce((acc, type) => {
    const type_ = cleanStatus(camelize(type.name))
    acc.push(type_)

    return acc
  }, [])

  return types.join(', ')
}

export const getPolicy = (policyData, policyType) => {
  const connectionStore = useConnectionStore()

  return policyData && policyData.find(item => {
    if (!item) return {}
    
    if (policyType === 'locations') {
      return item.location[POLICIES_IDS_NAMES_IN_POLICY_LIST[policyType]] === connectionStore.selectedTemplate[POLICIES_IDS_NAMES_IN_TEMPLATE[policyType]]
    }

    return item[POLICIES_IDS_NAMES_IN_POLICY_LIST[policyType]] === connectionStore.selectedTemplate[POLICIES_IDS_NAMES_IN_TEMPLATE[policyType]]
  })
}

