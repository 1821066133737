import { MONTHS } from '@/constants'

export const countOfDaysAgo = (data) => {
  const comparisonDate = new Date(data)
  const currentDate = new Date()

  const timeDifference = currentDate - comparisonDate

  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  return daysAgo
}

export const formatDate = (dateString) => {
  const date = new Date(dateString) // Convert to Date object
  const month = MONTHS[date.getUTCMonth()] // Get month abbreviation
  const day = date.getUTCDate() // Get day
  const year = date.getUTCFullYear() // Get year

  return `${month} ${day}, ${year}` // Format the date
}

export const isExpired = (dateString) => {
  const now = new Date() 
  const dateToCheck = new Date(dateString) 

  return dateToCheck < now
}